import * as $ from "jquery";

loading();

updateStockInfo();

$("form").on("submit", function (this: HTMLFormElement, e) {
  e.preventDefault();

  const data = new FormData(this);
  data.append("submission", "true");

  $("#error").addClass("hidden");
  $("#success").addClass("hidden");

  $.ajax({
    type: "POST",
    url: "add.php",
    data,
    processData: false,
    contentType: false,
    success: () => {
      showAlert("success", "Danke für Deine Teilnahme und Dein Päckchen ist bald unterwegs zu Dir");
      $("#form").css("min-height", "50vh");
      $(this).css("display", "none");
    },
    error: (error) => {
      if (error.responseJSON.code === 19) {
        showAlert(
          "error",
          "Mit dieser Mail wurde bereits eine Anmeldung durchgeführt!"
        );
      } else if (error.responseJSON.code === 1) {
        showAlert("error", error.responseJSON.message);
      } else {
        showAlert("error", "Es ist ein Fehler aufgetreten!");
      }
      updateStockInfo();
    },
  });
});

function showAlert(type: "success" | "error", message: string) {
  $("#" + type)
    .removeClass("hidden")
    .html(message);

  $("#" + type)[0].scrollIntoView();
}

function loading(isLoading = true) {
  if (!isLoading) {
    $("#loading").addClass("hidden");
  } else {
    $("#loading").removeClass("hidden");
  }
}

function updateStockInfo() {
  $.get(
    "add.php?orders",
    (result: {
      M: number;
      L: number;
      XL: number;
      XXL: number;
      XXXL: number;
    }) => {
      const sizes: (keyof typeof result)[] = ["M", "L", "XL", "XXL", "XXXL"];
      sizes.forEach((size) => {
        if (result[size]) {
          $(`#size${size}`).prop("disabled", true);
        }
      });
    }
  );

  $.get(
    "add.php?active",
    ({ isActive, isFinished }: { isActive: boolean; isFinished: boolean }) => {
      if (isActive) {
        if (isFinished) {
          $("#hinweis")
            .text(
              "Leider sind bereits alle Hoodies vergeben! Bis zum nächsten Jahr!"
            )
            .show();
          $("#hinweis").css("display", "block");
          $("form").hide();
        } else {
          $("form").show();
        }
      } else {
        $("#hinweis").show();
      }

      loading(false);
    }
  );
}
